import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/',
            component: () => import('../view/pages/Welcome'),
        },
        {
            path: '/contacts',
            component: () => import('../view/pages/Contacts'),
        },
        {
            path: '/partners',
            component: () => import('../view/pages/Partners'),
        },
        {
            path: '/pulse',
            component:  () => import('../view/pages/Pulse'),
        },
        {
            path: '/disciplines/:discipline',
            component: () => import('../view/pages/disciplines/Disciplines'),
        },
        {
            path: '/projects',
            component:  () => import('../view/pages/projects/Projects'),
        },

    ]
})
