const imagePreload = function(srcs) {
  let images = [];

  for (let i =0; i< srcs.length-1;i++) {
    images[i] = new Image();
    images[i].src = srcs[i]
  }

  return images;
}

export {imagePreload};