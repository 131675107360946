import axios from 'axios';

let token = "1600070846:AAH1AWQ6efJu_V0gv64hdInmuZ0hfsbXTGY";
let chat = "-1001268899022";
let host = "https://api.telegram.org";

const telegramSendMessage = function (message) {
  return axios.get(`${host}/bot${token}/sendMessage?chat_id=${chat}&text=${message}`);
}

export {telegramSendMessage}