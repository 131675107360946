<template>
  <div class="fx-col">
    <div class="section-item-title fx-row hidden-sm" :class="colorClass">
      <CompetenceIcon :icon="discipline.icon" :name="discipline.fullName" color></CompetenceIcon>
      {{ discipline.name }}
    </div>
    <div class="section-item-body">
      <p class="leading">{{ discipline.title }}</p>
      <p>{{ discipline.description }}</p>
      <p v-html="discipline.content[language.code]"></p>
    </div>
    <div class="section-item-footer fx-col">
      <div class="title">
        {{ discipline.footer }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Development",
  props: ["discipline"],
  data() {
    return {}
  },
  components: {
    CompetenceIcon: () => import("@/components/CompetenceIcon"),
  },
  computed: {
    language() {
      // Language will be in the Store later, once localization will be implemented
      return {
        title: "English",
        code: "en"
      }
    },
    colorClass: function () {
      return `color-${this.discipline.color}`
    }
  }
}
</script>

<style scoped lang="scss">
.section-item-title {
  &.color-darkblue {
    color: $darkblue;
  }

  &.color-yellow {
    color: $yellow;
  }

  &.color-pink {
    color: $pink;
  }

  &.color-orange {
    color: $orange;
  }

  &.color-red {
    color: $red;
  }

  &.color-cyan {
    color: $cyan;
  }

  &.color-green {
    color: $green;
  }

  &.color-blue {
    color: $blue;
  }
}

</style>
