<template>
  <header>
    <div class="ui-header">
      <div class="logo fx-row hidden-sm" @click="onLogoClick">
        <div class="logo-img">
          <svg width="25" height="29" viewBox="0 0 25 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M22.4179 17.0102C23.7792 17.0102 24.8827 15.9691 24.8827 14.6849C24.8827 13.4007 23.7792 12.3596 22.4179 12.3596C21.0566 12.3596 19.9531 13.4007 19.9531 14.6849C19.9531 15.9691 21.0566 17.0102 22.4179 17.0102Z"
                fill="white"/>
            <path
                d="M15.8452 17.0102C17.2064 17.0102 18.3099 15.9691 18.3099 14.6849C18.3099 13.4007 17.2064 12.3596 15.8452 12.3596C14.4839 12.3596 13.3804 13.4007 13.3804 14.6849C13.3804 15.9691 14.4839 17.0102 15.8452 17.0102Z"
                fill="white"/>
            <path
                d="M9.15473 17.0102C10.516 17.0102 11.6195 15.9691 11.6195 14.6849C11.6195 13.4007 10.516 12.3596 9.15473 12.3596C7.79347 12.3596 6.68994 13.4007 6.68994 14.6849C6.68994 15.9691 7.79347 17.0102 9.15473 17.0102Z"
                fill="white"/>
            <path
                d="M2.46479 17.0102C3.82605 17.0102 4.92958 15.9691 4.92958 14.6849C4.92958 13.4007 3.82605 12.3596 2.46479 12.3596C1.10352 12.3596 0 13.4007 0 14.6849C0 15.9691 1.10352 17.0102 2.46479 17.0102Z"
                fill="white"/>
            <path
                d="M9.15516 10.2557C10.322 10.2557 11.2678 9.3634 11.2678 8.26264C11.2678 7.16188 10.322 6.26953 9.15516 6.26953C7.98836 6.26953 7.04248 7.16188 7.04248 8.26264C7.04248 9.3634 7.98836 10.2557 9.15516 10.2557Z"
                fill="white"/>
            <path
                d="M9.15516 23.2111C10.322 23.2111 11.2678 22.3187 11.2678 21.218C11.2678 20.1172 10.322 19.2249 9.15516 19.2249C7.98836 19.2249 7.04248 20.1172 7.04248 21.218C7.04248 22.3187 7.98836 23.2111 9.15516 23.2111Z"
                fill="white"/>
            <path
                d="M2.46485 28.8582C3.502 28.8582 4.34278 28.0651 4.34278 27.0866C4.34278 26.1081 3.502 25.3149 2.46485 25.3149C1.42769 25.3149 0.586914 26.1081 0.586914 27.0866C0.586914 28.0651 1.42769 28.8582 2.46485 28.8582Z"
                fill="white"/>
            <path
                d="M2.46485 4.05503C3.502 4.05503 4.34278 3.26183 4.34278 2.28337C4.34278 1.30491 3.502 0.511719 2.46485 0.511719C1.42769 0.511719 0.586914 1.30491 0.586914 2.28337C0.586914 3.26183 1.42769 4.05503 2.46485 4.05503Z"
                fill="white"/>
            <path
                d="M2.46479 17.0102C3.82605 17.0102 4.92958 15.9691 4.92958 14.6849C4.92958 13.4007 3.82605 12.3596 2.46479 12.3596C1.10352 12.3596 0 13.4007 0 14.6849C0 15.9691 1.10352 17.0102 2.46479 17.0102Z"
                fill="white"/>
            <path
                d="M2.46485 28.8582C3.502 28.8582 4.34278 28.0651 4.34278 27.0866C4.34278 26.1081 3.502 25.3149 2.46485 25.3149C1.42769 25.3149 0.586914 26.1081 0.586914 27.0866C0.586914 28.0651 1.42769 28.8582 2.46485 28.8582Z"
                fill="white"/>
            <path
                d="M2.46485 4.05503C3.502 4.05503 4.34278 3.26183 4.34278 2.28337C4.34278 1.30491 3.502 0.511719 2.46485 0.511719C1.42769 0.511719 0.586914 1.30491 0.586914 2.28337C0.586914 3.26183 1.42769 4.05503 2.46485 4.05503Z"
                fill="white"/>
          </svg>
        </div>
        <div class="logo-text">
          Center <span>of</span> Reactive Programming
        </div>
      </div>
      <div class="hidden-md">
        <div class="logo fx-row" v-if="!section.title" @click="onLogoClick">
          <div class="logo-img">
            <svg width="25" height="29" viewBox="0 0 25 29" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M22.4179 17.0102C23.7792 17.0102 24.8827 15.9691 24.8827 14.6849C24.8827 13.4007 23.7792 12.3596 22.4179 12.3596C21.0566 12.3596 19.9531 13.4007 19.9531 14.6849C19.9531 15.9691 21.0566 17.0102 22.4179 17.0102Z"
                  fill="white"/>
              <path
                  d="M15.8452 17.0102C17.2064 17.0102 18.3099 15.9691 18.3099 14.6849C18.3099 13.4007 17.2064 12.3596 15.8452 12.3596C14.4839 12.3596 13.3804 13.4007 13.3804 14.6849C13.3804 15.9691 14.4839 17.0102 15.8452 17.0102Z"
                  fill="white"/>
              <path
                  d="M9.15473 17.0102C10.516 17.0102 11.6195 15.9691 11.6195 14.6849C11.6195 13.4007 10.516 12.3596 9.15473 12.3596C7.79347 12.3596 6.68994 13.4007 6.68994 14.6849C6.68994 15.9691 7.79347 17.0102 9.15473 17.0102Z"
                  fill="white"/>
              <path
                  d="M2.46479 17.0102C3.82605 17.0102 4.92958 15.9691 4.92958 14.6849C4.92958 13.4007 3.82605 12.3596 2.46479 12.3596C1.10352 12.3596 0 13.4007 0 14.6849C0 15.9691 1.10352 17.0102 2.46479 17.0102Z"
                  fill="white"/>
              <path
                  d="M9.15516 10.2557C10.322 10.2557 11.2678 9.3634 11.2678 8.26264C11.2678 7.16188 10.322 6.26953 9.15516 6.26953C7.98836 6.26953 7.04248 7.16188 7.04248 8.26264C7.04248 9.3634 7.98836 10.2557 9.15516 10.2557Z"
                  fill="white"/>
              <path
                  d="M9.15516 23.2111C10.322 23.2111 11.2678 22.3187 11.2678 21.218C11.2678 20.1172 10.322 19.2249 9.15516 19.2249C7.98836 19.2249 7.04248 20.1172 7.04248 21.218C7.04248 22.3187 7.98836 23.2111 9.15516 23.2111Z"
                  fill="white"/>
              <path
                  d="M2.46485 28.8582C3.502 28.8582 4.34278 28.0651 4.34278 27.0866C4.34278 26.1081 3.502 25.3149 2.46485 25.3149C1.42769 25.3149 0.586914 26.1081 0.586914 27.0866C0.586914 28.0651 1.42769 28.8582 2.46485 28.8582Z"
                  fill="white"/>
              <path
                  d="M2.46485 4.05503C3.502 4.05503 4.34278 3.26183 4.34278 2.28337C4.34278 1.30491 3.502 0.511719 2.46485 0.511719C1.42769 0.511719 0.586914 1.30491 0.586914 2.28337C0.586914 3.26183 1.42769 4.05503 2.46485 4.05503Z"
                  fill="white"/>
              <path
                  d="M2.46479 17.0102C3.82605 17.0102 4.92958 15.9691 4.92958 14.6849C4.92958 13.4007 3.82605 12.3596 2.46479 12.3596C1.10352 12.3596 0 13.4007 0 14.6849C0 15.9691 1.10352 17.0102 2.46479 17.0102Z"
                  fill="white"/>
              <path
                  d="M2.46485 28.8582C3.502 28.8582 4.34278 28.0651 4.34278 27.0866C4.34278 26.1081 3.502 25.3149 2.46485 25.3149C1.42769 25.3149 0.586914 26.1081 0.586914 27.0866C0.586914 28.0651 1.42769 28.8582 2.46485 28.8582Z"
                  fill="white"/>
              <path
                  d="M2.46485 4.05503C3.502 4.05503 4.34278 3.26183 4.34278 2.28337C4.34278 1.30491 3.502 0.511719 2.46485 0.511719C1.42769 0.511719 0.586914 1.30491 0.586914 2.28337C0.586914 3.26183 1.42769 4.05503 2.46485 4.05503Z"
                  fill="white"/>
            </svg>
          </div>
          <div class="logo-text">
            Center <span>of</span> Reactive Programming
          </div>
        </div>

        <div class="section-title" v-else>
          <div class="title-number">{{ section.number }}</div>
          <div class="title-text">{{ section.title }}</div>
        </div>
      </div>

      <div class="menu fx-row">
        <div v-for="(section, index) in sections" :key="index" class="menu-item"
             :class="{'active': $route.path.includes(section.path)}" @click="goToPage(section)">
          <span>0{{ index + 1 }}</span>
          <span class="fx-row">{{ section.title }}</span>
        </div>
      </div>
      <div class="menu-trigger hidden" @click="onMenuToggle">
        <template v-if="!isMenuOpen">
          <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.5">
              <path d="M0 1H20" stroke="white"/>
              <path d="M0 7H20" stroke="white"/>
              <path d="M0 13H20" stroke="white"/>
            </g>
          </svg>
        </template>
        <template v-if="isMenuOpen">
          <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.5">
              <path d="M21.2132 21.2132L14.1421 14.1421L21.2132 7.07102" stroke="white"/>
              <path d="M7.07102 7.07102L14.1421 14.1421L7.07102 21.2132" stroke="white"/>
            </g>
          </svg>
        </template>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "Header",
  data: function () {
    return {
      sections: [
        {title: 'Pulse', path: '/pulse', ref: 'pulse'},
        {title: 'Disciplines', path: '/disciplines', ref: 'disciplines'},
        {title: 'Projects', path: '/projects', ref: 'projects'},
        {title: 'Partners', path: '/partners', ref: 'partners'},
        {title: 'Contacts', path: '/contacts', ref: 'contacts'},
      ],
      isMenuOpen: false,
      menuHeight: 'unset',
      section: {},
    }
  },
  methods: {
    onMenuToggle() {
      this.toggleMenu();
    },
    onLogoClick() {
      this.scrollMeTo('welcome');
    },
    goToPage(section) {
      this.toggleMenu(false);
      this.$router.push(section.path);
      this.scrollMeTo(section.ref);
    },
    scrollMeTo(refName) {
      const element = document.querySelector(`#${refName}`);
      const body = document.querySelector(`body`);
      const top = element.offsetTop;

      body.scroll({ top: top, behavior: 'smooth' });
    },
    toggleMenu(isMenuOpen) {
      this.isMenuOpen = (isMenuOpen !== undefined) ? isMenuOpen : !this.isMenuOpen;
      const elem = document.querySelector('.menu');
      elem.classList.toggle('open', this.isMenuOpen);
    }
  }
}
</script>

<style scoped lang="scss">
footer {
  margin-top: auto;
  padding-top: 45px;
  justify-content: space-between;

  .copyright {
    font-weight: 300;
    font-size: 14px;
    line-height: 14px;
    opacity: 0.7;
  }

  .social svg {
    opacity: 0.6;
    margin-left: 20px;
  }

  @media screen and (max-width: $breakpoint-sm) {
    flex-direction: column;
    .social {
      order: -1;
      margin-bottom: 26px;
    }
  }

}
header {
  position: fixed;
  z-index: 100;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  padding: 23px 84px 23px 30px;
  height: 86px;
}

.ui-header {
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}

.logo {
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;

  .logo-img {
    margin-right: 30px;
    line-height: 0;
    top: -2px;
    position: relative;
  }

  span {
    font-weight: normal
  }
}

.menu-item {
  position: relative;
  display: flex;
  cursor: pointer;
  font-weight: bold;
  font-size: 13px;
  line-height: 13px;
  text-transform: uppercase;
  opacity: 0.8;
  margin-left: 48px;

  svg {
    display: none
  }

  span:first-of-type {
    margin-right: 0.5em
  }

  &:hover, &.active {
    &:after {
      content: '';
      position: absolute;
      left: 50%;
      bottom: -10px;
      height: 2px;
      width: 2px;
      border-radius: 3px;
      background: #FFFFFF;
      opacity: 0.8;
      box-shadow: $dot-shadow;
    }
  }
}

@media screen and (max-width: $breakpoint-sm) {
  header {
    padding: 24px 17px 6px 24px;
  }
  .logo {
    font-size: 12px;

    .logo-img {
      margin-right: 12px;
    }
  }
  .menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 0;
    position: absolute;
    z-index: 999;
    top: 69px;
    right: 0;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    transition: all 0.2s ease-out;
    background: $background-base;

    &.open {
      height: calc(100vh - 70px);
    }
  }
  .menu-item {
    margin: 16px 52px;
    opacity: 1;

    &:first-of-type {
      margin-top: 81px;
    }

    span:first-of-type {
      display: block;
      font-size: 24px;
      line-height: 24px;
      font-weight: 700;
    }

    span:last-of-type {
      font-size: 16px;
      line-height: 16px;
      font-weight: 400;
      text-transform: capitalize;
    }

    &:hover, &.active {
      &:after {
        display: none;
      }
    }
  }
  .menu-trigger {
    display: block;
  }
}
</style>
