<template>
  <div class="section-container section-container-projects" id="projects">
    <section>
      <div class="section-title-container">
        <div class="section-title">
          <div class="title-number">03</div>
          <div class="title-text">Projects</div>
        </div>

        <div class="section-menu hidden-sm">
          <SectionMenu
              :defaultItem="defaultProjectName"
              :menuItems="menuItems"
              @selectItem="getCurrentProject">
          </SectionMenu>
        </div>
      </div>
      <div class="section-body fx-col">
        <div class="corner-out"></div>
        <Project :project="currentProject" @nextProject="getCurrentProject('next')"></Project>
        <div class="corner-out corner-close"></div>
      </div>

      <div class="section-menu hidden-md">
        <div class="title">See other projects</div>
        <SectionMenu
            :defaultItem="defaultProjectName"
            :menuItems="menuItems"
            @selectItem="getCurrentProject">
        </SectionMenu>
      </div>

    </section>
  </div>
</template>

<script>
import Project from "./Project";
import projects from './projects.json'

export default {
  name: "Projects",
  components: {
    Project,
    SectionMenu: () => import("@/components/SectionMenu"),
  },
  data: function () {
    return {
      projects: projects,
      currentProject: {},
      defaultProjectName: 'Ankey Asap',
      menuItems: [],
    }
  },
  methods: {
    getCurrentProject(name) {
      if (name === 'next') {
        let index = this.projects.findIndex(project => project.name === this.currentProject.name) + 1;
        this.currentProject = index === this.projects.length ? this.projects[0] : this.projects[index];
      } else {
        this.currentProject = this.projects.find(project => project.name === name);
      }
    },
    getMenuItems() {
      this.menuItems = this.projects.reduce((acc, project) => {
        return [...acc, project.name]
      }, []);
    }
  },
  mounted: function () {
    this.getMenuItems();
    this.getCurrentProject(this.defaultProjectName)
  }

}
</script>

<style scoped lang="scss">

.section-menu {
  mask-image: linear-gradient(to right, transparent, rgba(0, 0, 0, 1) 15%, rgba(0, 0, 0, 1) 90%, transparent);
  @media screen and (min-width: $breakpoint-sm + 1) {
    width: 50%;
    margin-left: auto;
  }
}

.section-menu .title {
  text-align: center;
  margin: 5px auto 24px;
}
</style>
