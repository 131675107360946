<template>
  <div id="app">
    <Header></Header>
    <Welcome></Welcome>
    <Pulse></Pulse>
    <Disciplines></Disciplines>
    <Projects></Projects>
    <Partners></Partners>
    <Contacts></Contacts>
  </div>
</template>

<script>
import Header from "@/view/layout/Header";
import Welcome from "@/view/pages/Welcome";
import Pulse from "@/view/pages/Pulse";
import Disciplines from "@/view/pages/disciplines/Disciplines";
import Projects from "@/view/pages/projects/Projects";
import Contacts from "@/view/pages/Contacts";
import Partners from "@/view/pages/Partners";
import {imagePreload} from "@/utils/image.preload";

export default {
  name: 'App',
  components: {
    Partners,
    Contacts,
    Projects,
    Disciplines,
    Pulse,
    Welcome,
    Header
  },
  created() {
    let imageSrcs = [];
    const sphereImagesArrayLength = 49;

    for (let i = 0; i<=sphereImagesArrayLength; i++) {
      imageSrcs.push(`/images/sphere/s-${i}.png`);
    }

    imagePreload(imageSrcs)
  }
}
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
</style>
