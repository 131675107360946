import Vue from 'vue'
import underscore from 'vue-underscore';
import { BootstrapVue } from 'bootstrap-vue'
import router from './router'
import App from './App.vue'

// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(underscore);
Vue.use(BootstrapVue);

Vue.config.productionTip = false


new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
