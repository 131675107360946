<template>
  <div class="section-container section-container-welcome" id="welcome">
    <section>
      <div class="corner-out"></div>
      <div class="section-body">
        <div class="title">
          <span>Full-cycle </span> <span>software production</span>
        </div>
        <div class="subtitle dot-after dot-before">
          <div class="subtitle-item dot-before ">Research</div>
          <div class="subtitle-item dot-before">UX & Design</div>
          <div class="subtitle-item dot-before">Development</div>
          <div class="subtitle-item dot-before dot-after">DevOps</div>
        </div>
      </div>
      <div class="corner-out corner-close"></div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Welcome"
}
</script>

<style scoped lang="scss">

section {
  justify-content: space-between;
  max-width: none;
}

.section-body {
  background: url("/images/map.png") no-repeat 0 0;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  @media screen and (max-width: $breakpoint-xs) {
    background-image: url("/images/map-mobile.png");
  }
}

.title {
  font-weight: 300;
  font-size: 64px;
  line-height: 39px;
  text-align: center;
  text-shadow: $text-shadow;
  @media screen and (max-width: $breakpoint-sm) {
    font-size: 32px;
  }
  @media screen and (max-width: $breakpoint-xs) {
    span {
      display: block
    }
  ;
  }
}

@mixin dot-before {
  &:before {
    content: '';
    height: 4px;
    width: 4px;
    background-color: $white;
    position: relative;
    border-radius: 50%;
    left: -40px;
  }
}

@mixin dot-after {
  &:after {
    content: '';
    height: 4px;
    width: 4px;
    background-color: $white;
    position: relative;
    border-radius: 50%;
    right: -40px;
  }
  @media screen and (max-width: $breakpoint-sm) {
    &:after {
      right: -12px;
    }
  }
}

.subtitle {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  @include dot-after;
  @include dot-before;
  @media screen and (max-width: $breakpoint-sm) {
    flex-direction: column;
    &:before, &:after {
      display: none
    }
  }
}

.subtitle-item {
  display: flex;
  align-items: center;
  margin: 0 40px;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  @include dot-before;

  &:last-of-type {
    @include dot-after;
  }

  @media screen and (max-width: $breakpoint-sm) {
    margin: 0 12px 24px;
    font-size: 14px;
    line-height: 14px;
    @include dot-after;
    &:before {
      left: -12px;
      transform: scale(0.5);
    }
    &:after {
      right: -12px;
      transform: scale(0.5);
    }
  }
}

</style>
