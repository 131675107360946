<template>
  <div class="section-container section-container-competence" id="disciplines">
    <section>
      <div class="section-title-container">
        <div class="section-title">
          <div class="title-number">02</div>
          <div class="title-text">Disciplines</div>
        </div>

        <div class="section-menu fx-row hidden-sm">
          <div class="item fx-col" v-for="(discipline, index) in disciplines" :key="discipline.name"
               :class="{'active': isDisciplineActive(discipline)}">
            <div class="corner-in"></div>
            <div class="item-body" v-on:click="goToDisciplinePage(discipline, index)">{{ discipline.fullName }}</div>
            <div class="corner-in corner-close"></div>
          </div>
        </div>
      </div>

      <div class="section-menu">
        <div class="overlay">
          <agile :options="sliderOption" ref="menu">
            <div v-for="(discipline, index) in disciplines" :key="discipline.name"
                 v-on:click="goToDisciplinePage(discipline, index)">
            </div>
          </agile>
        </div>
      </div>

      <div class="section-body fx-col">
        <div class="corner-out"></div>
        <div class="fx-row ui-discipline-container">
          <div class="ui-sphere hidden-sm">
            <img :src="sphereImgSrc" alt="">
          </div>
          <div class="section-item" :class="disciplineAnimationClass">
            <Discipline :discipline="activeDiscipline"></Discipline>
          </div>
        </div>
        <div class="corner-out corner-close"></div>

      </div>

    </section>
  </div>
</template>

<script>
import {VueAgile} from 'vue-agile';
import Discipline from "@/view/pages/disciplines/Discipline";
import disciplines from "./disciplines.json"

export default {
  name: "Disciplines",
  components: {
    agile: VueAgile,
    Discipline: Discipline,
  },
  data: function () {
    return {
      disciplines: disciplines,
      sliderOption: {
        initialSlide: 2,
        infinite: true,
        dots: false,
        centerMode: false,
        navButtons: false,
        slidesToShow: 3,
        responsive: [
          {
            breakpoint: 460,
            settings: {
              slidesToShow: 3
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 5
            },
          },

        ]
      },
      isMobileFirst: true,
      activeDisciplineName: "research",
      activeDisciplineSphereIndex: 9,
      maxSphereAnimationStepTimeout: 180,
      isTransition: false
    }
  },
  computed: {
    sphereImgSrc() {
      return `/images/sphere/s-${this.activeDisciplineSphereIndex}.png`;
    },
    activeDiscipline() {
      return this.$_.findWhere(this.disciplines, {name: this.activeDisciplineName});
    },
    disciplineAnimationClass() {
      return this.isTransition ? 'ui-transition' : 'ui-no-transition';
    }
  },
  methods: {
    goToDisciplinePage(discipline) {
      this.isTransition = true;
      this.animateSphere({
        initialPosition: this.activeDiscipline.sphere,
        currentPosition: this.activeDiscipline.sphere,
        targetPosition: discipline.sphere
      });
      this.activeDisciplineName = discipline.name;
    },

    animateSphere({initialPosition, currentPosition, targetPosition, timeout = 0}) {
      if (initialPosition < targetPosition && currentPosition < targetPosition) { // Animation direction
        currentPosition++;

        this.rotateSphere(currentPosition, timeout).then(() => {
          this.animateSphere({
            initialPosition,
            currentPosition,
            targetPosition,
            timeout
          });
        });
      } else if (initialPosition > targetPosition && currentPosition > targetPosition) {
        currentPosition--;

        this.rotateSphere(currentPosition, timeout).then(() => {
          this.animateSphere({
            initialPosition,
            currentPosition,
            targetPosition,
            timeout
          });
        });
      } else {
        this.isTransition = false; // Show description once sphere animation ended
      }

      if (timeout === 0) { // animation issues, we want to start animation right away and then set up timeout on every step
        timeout = this.getSphereAnimationTimeout(initialPosition, targetPosition)
      }
    },

    rotateSphere(position, timeout) {
      return new Promise((resolve) => {
        setTimeout(() => {
          this.activeDisciplineSphereIndex = position;
          resolve();
        }, timeout)
      })
    },

    getSphereAnimationTimeout(initialPosition, targetPosition) {
      let animationStepTimeout = 2500 / Math.abs(targetPosition - initialPosition)

      if (animationStepTimeout > this.maxSphereAnimationStepTimeout) { // Increases animation speed when there are not a lot of frames
        return this.maxSphereAnimationStepTimeout;
      } else {
        return animationStepTimeout;
      }
    },

    isDisciplineActive(discipline) {
      return this.activeDisciplineName === discipline.name;
    }
  }
}
</script>

<style scoped lang="scss">
.section-body {
  flex-grow: 1;
  position: relative;
  justify-content: flex-start;
}

.ui-discipline-container {
  align-items: flex-start;
}

.ui-sphere {
  margin: 40px 0;
  width: 60%;
  padding-right: 100px;

  img {
    width: 100%;
    height: auto;
  }

  @media screen and (max-width: $breakpoint-sm) {
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin-bottom: 0;
    padding: unset;
    background-position: 50% 0;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}

@keyframes animation-fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes animation-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.ui-transition {
  opacity: 0;
  animation: .4s animation-fade-out;
}

.ui-no-transition {
  opacity: 1;
  animation: .8s animation-fade-in;
}

.section-item {
  align-self: flex-start;
  width: 50%;
  height: 100%;
  @media screen and (max-width: $breakpoint-sm) {
    width: 100%;
  }
}

.section-menu {
  min-width: 50%;
  justify-content: space-between;

  .item {
    font-weight: bold;
    font-size: 13px;
    line-height: 13px;
    text-transform: uppercase;
    cursor: pointer;

    &:not(.active) {
      .corner-in {
        display: none
      }

      opacity: 0.3;
    }

    .item-body {
      white-space: nowrap;
      padding: 0 20px;
    }
  }

  @media screen and (max-width: $breakpoint-sm) {
    width: calc(100% + 24px);
    overflow: hidden;
    margin: 0 -24px 24px 0;

    .overlay {
      width: calc(100vw + 50px);
    }
  }
}
</style>
