<template>
  <div class="section-container section-container-contacts" id="contacts">
    <section>
      <div class="section-title-container">
        <div class="section-title">
          <div class="title-number">05</div>
          <div class="title-text">Contacts</div>
        </div>
      </div>

      <div class="section-body fx-row">
        <div class="contact-text hidden-md">
          Please tell us about your project in a letter - <br>
          contact@crplab.ru - or in the form here.
        </div>
        <div class="contact-form fx-col">
          <div class="corner-out"></div>
          <div class="form fx-col">
            <div class="form-field" :class="validationClass('name')">
              <input placeholder="Name" type="text" v-model="formData.name">
            </div>
            <div class="fx-row">
              <div class="form-field" :class="validationClass('email')">
                <input placeholder="E-mail" type="email" v-model="formData.email">
              </div>
              <div class="form-field" :class="validationClass('phone')">
                <input placeholder="Phone number" type="tel" v-model="formData.phone">
              </div>
            </div>
            <div class="form-field" :class="validationClass('message')">
              <textarea placeholder="Message" rows="1" v-model="formData.message"></textarea>
            </div>
            <div class="form-field">
              <div class="form-button ui-form-button" @click="onSendClick">
                <div class="corner-in"></div>
                <span>Send message</span>
                <div class="corner-in corner-close"></div>
              </div>
            </div>
          </div>
          <div class="corner-out corner-close"></div>
        </div>
        <div class="contact-text">
          <p class="hidden-sm">We are always looking for new partners and ambitious tasks.</p>
          <p class="hidden-sm">Please tell us about your project via email - <br>
            contact@crplab.ru - or in the form here.</p>
          <div class="contact-address">
            <p>+7 (812) 329 28 14</p>
            <p>195220 | Saint Petersburg<br>
              Nepokorennykh avenue 49a, office 410</p>
          </div>
          <svg width="144" height="138" viewBox="0 0 144 138" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.3">
              <path
                  d="M37.8438 107.982C46.4969 104.782 56.998 100.321 63.2889 97.1722C69.6839 93.9313 77.26 92.2378 83.9487 90.5877C92.0326 88.6308 99.009 87.0969 102.087 83.4541C107.919 76.64 107.552 54.2073 107.209 34.5283C107.16 29.0211 106.994 23.8016 107 18.9858C107.11 7.78767 106.229 3.01522 103.64 1.97041C101.915 1.27386 99.6873 2.31551 95.9421 5.62212C93.8615 7.45912 91.4565 9.76749 88.8312 12.4554C82.8524 18.4741 71.5313 36.0552 59.7452 54.7869C45.1441 77.6692 30.1023 101.31 22.3489 107.416C19.9683 109.333 18.4997 110.814 19.2096 112.038C19.5645 112.649 20.5376 114.381 37.8438 107.982ZM23.1628 108.547C31.1243 102.258 46.2701 78.5252 60.8712 55.6429L60.9752 55.5511C72.7491 37.0152 83.9662 19.5259 89.8409 13.599C95.5076 7.85584 100.85 2.58401 103.059 3.40876C105.36 4.33748 105.55 12.3107 105.526 18.9928C105.52 23.8086 105.581 29.12 105.63 34.6272C105.985 54.1104 106.273 76.2432 100.857 82.6899C98.1033 85.8613 91.4146 87.5114 83.5387 89.2846C76.7582 90.8307 69.078 92.616 62.579 95.9488C56.3922 99.006 45.995 103.375 37.3419 106.575C26.0696 110.791 21.5044 111.492 20.5375 111.235C20.6538 110.948 21.1984 110.097 23.1628 108.547Z"
                  fill="url(#paint0_linear)"/>
              <path
                  d="M48.6866 94.8931C56.7094 93.9153 88.5006 80.6481 93.6471 72.2189C95.0729 69.8499 96.7924 59.6361 97.6307 47.795C98.9768 29.4 97.2877 24.9708 96.174 23.919C95.6109 23.491 95.1275 23.3628 94.4359 23.4183C93.6404 23.5656 92.7286 24.0006 91.6883 24.9191C88.9834 27.3072 85.4158 32.4923 81.1994 38.6201C76.4507 45.403 71.1574 53.0368 65.454 59.3675C55.7362 70.1675 42.9831 88.6428 45.6146 93.7201C46.0736 94.2399 46.8079 95.0717 48.6866 94.8931ZM47.0465 92.8259C45.3758 89.6755 53.7963 74.4704 66.6718 60.3274C72.4792 53.9049 77.8766 46.1792 82.5213 39.4882C87.5943 32.234 92.3553 25.2552 94.7297 25.009C94.9255 25.0212 95.1213 25.0333 95.2131 25.1373C96.4187 26.293 97.2327 33.7151 96.1558 47.802C95.3543 59.0556 93.6225 69.4653 92.417 71.4548C87.4908 79.5044 56.3116 92.4164 48.4846 93.4063C47.4015 93.4375 47.1383 92.9298 47.0465 92.8259Z"
                  fill="url(#paint1_linear)"/>
              <path
                  d="M86.4092 66.954C87.8473 64.3892 89.1752 60.4415 89.8116 56.5493C90.2583 54.1198 90.7234 49.8238 89.0466 48.344C88.3918 47.8121 87.4371 47.3598 85.7971 48.4378C85.0811 48.8849 84.4568 49.436 83.7286 50.0789C75.9262 56.9677 67.2366 73.3356 67.7751 77.3005C67.8302 77.992 68.3014 78.316 68.9807 78.4564C72.3771 79.158 83.5208 72.2794 86.4092 66.954ZM86.7946 49.7773C87.3025 49.5138 87.9145 49.1586 88.1899 49.4705C89.4872 50.7302 88.8203 59.8298 85.1792 66.1898C82.1867 71.6071 71.5081 77.335 69.3662 77.0058C69.2193 73.0652 78.9983 54.9955 86.7946 49.7773Z"
                  fill="url(#paint2_linear)"/>
            </g>
            <defs>
              <linearGradient id="paint0_linear" x1="92.5219" y1="92.0179" x2="51.1551" y2="45.1646"
                              gradientUnits="userSpaceOnUse">
                <stop stop-color="#F7F8D6" stop-opacity="0.4"/>
                <stop offset="1" stop-color="#CCCCCC" stop-opacity="0"/>
              </linearGradient>
              <linearGradient id="paint1_linear" x1="87.8299" y1="77.9957" x2="63.1863" y2="50.0835"
                              gradientUnits="userSpaceOnUse">
                <stop stop-color="#F7F8D6" stop-opacity="0.4"/>
                <stop offset="1" stop-color="#CCCCCC" stop-opacity="0"/>
              </linearGradient>
              <linearGradient id="paint2_linear" x1="84.4185" y1="69.8038" x2="74.3299" y2="58.3771"
                              gradientUnits="userSpaceOnUse">
                <stop stop-color="#F7F8D6" stop-opacity="0.4"/>
                <stop offset="1" stop-color="#CCCCCC" stop-opacity="0"/>
              </linearGradient>
            </defs>
          </svg>

        </div>
      </div>

      <footer class="fx-row">
        <div class="copyright">
          Center of Reactive Programming &copy; 2014 - {{year}}
        </div>
        <div class="social">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <a href="https://www.linkedin.com/company/10941015">
            <path opacity="0.6"
                  d="M17.868 0.625H2.13203C1.3 0.625 0.625 1.3 0.625 2.13203V17.868C0.625 18.7 1.3 19.375 2.13203 19.375H17.868C18.7 19.375 19.375 18.7 19.375 17.868V2.13203C19.375 1.3 18.7 0.625 17.868 0.625ZM17.868 17.875C7.37266 17.8727 2.125 17.8703 2.125 17.868C2.12734 7.37266 2.12969 2.125 2.13203 2.125C12.6273 2.12734 17.875 2.12969 17.875 2.13203C17.8727 12.6273 17.8703 17.875 17.868 17.875ZM3.40469 7.65391H6.18672V16.6023H3.40469V7.65391ZM4.79688 6.43047C5.68516 6.43047 6.40937 5.70859 6.40937 4.81797C6.40937 4.60621 6.36767 4.39653 6.28663 4.20089C6.2056 4.00525 6.08682 3.82749 5.93709 3.67776C5.78735 3.52802 5.60959 3.40925 5.41395 3.32821C5.21831 3.24718 5.00863 3.20547 4.79688 3.20547C4.58512 3.20547 4.37544 3.24718 4.1798 3.32821C3.98416 3.40925 3.8064 3.52802 3.65667 3.67776C3.50693 3.82749 3.38815 4.00525 3.30712 4.20089C3.22608 4.39653 3.18437 4.60621 3.18437 4.81797C3.18203 5.70859 3.90391 6.43047 4.79688 6.43047ZM10.7102 12.175C10.7102 11.0078 10.9328 9.87812 12.3789 9.87812C13.8039 9.87812 13.825 11.2117 13.825 12.25V16.6023H16.6047V11.6945C16.6047 9.28516 16.0844 7.43125 13.2695 7.43125C11.9172 7.43125 11.0102 8.17422 10.6375 8.87734H10.6V7.65391H7.93047V16.6023H10.7102V12.175Z"
                  fill="white"/>
            </a>
          </svg>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <a href="https://www.facebook.com/crplab.ru">
            <path opacity="0.6"
                  d="M18.625 0.625H1.375C0.960156 0.625 0.625 0.960156 0.625 1.375V18.625C0.625 19.0398 0.960156 19.375 1.375 19.375H18.625C19.0398 19.375 19.375 19.0398 19.375 18.625V1.375C19.375 0.960156 19.0398 0.625 18.625 0.625ZM17.875 17.875H13.5602V12.1141H15.9977L16.3633 9.28516H13.5602V7.47812C13.5602 6.65781 13.7875 6.1 14.9617 6.1H16.4594V3.56875C16.1992 3.53359 15.3109 3.45625 14.275 3.45625C12.1141 3.45625 10.6352 4.77578 10.6352 7.19687V9.28281H8.19297V12.1117H10.6375V17.875H2.125V2.125H17.875V17.875Z"
                  fill="white"/>
            </a>
          </svg>
        </div>
      </footer>

    </section>

  </div>
</template>

<script>
import _ from 'underscore';
import {telegramSendMessage} from '../../telegramBot/message.bot'

export default {
  name: "Contacts",
  data() {
    return {
      year: new Date().getFullYear(),
      formData: {
        name: null,
        email: null,
        phone: null,
        message: null
      },
      isValidationActive: false
    }
  },
  computed: {
    isValid() {
      return this.formData.name && this.formData.email && this.formData.phone && this.formData.message;
    },
  },
  methods: {
    onSendClick() {
      if (this.isValid) {
        this.isValidationActive = false;
        const message = this.prepareMessage();
        const sendMessageRequest = telegramSendMessage(message);
        sendMessageRequest
          .then(this.clearForm)
          .catch((error) => {
            console.error(`Telegram send message: ${error}`);
          });
      } else {
        this.isValidationActive = true;
      }
    },
    prepareMessage() {
      return `${this.formData.name}. ${this.formData.email}. ${this.formData.phone}. ${this.formData.message}`;
    },
    validationClass(fieldName) {
      return this.isValidationActive && (_.isNull(this.formData[fieldName]) || _.isEmpty(this.formData[fieldName])) ? "ui-error" : "";
    },
    clearForm() {
      this.formData.name = null;
      this.formData.email = null;
      this.formData.phone = null;
      this.formData.message = null;
    }
  }
}
</script>

<style scoped lang="scss">
section {
  background: url("/images/contacts-bg.svg") bottom center no-repeat;
  background-size: contain;
  padding-bottom: 30px;
  @media screen and (max-width: $breakpoint-sm) {
    background: url("/images/contacts-bg-mobile.svg") bottom center no-repeat;
    background-size: contain;
  }
}

.section-body {
  align-items: stretch;
  position: relative;
  @media screen and (max-width: $breakpoint-sm) {
    flex-direction: column;
    margin-top: 24px;
  }
}

.contact-form {
  width: calc(60% - 90px);
  margin-right: 90px;
  justify-content: space-between;
  @media screen and (max-width: $breakpoint-sm) {
    width: unset;
    margin-right: unset;
  }
}

.contact-text {
  svg {
    position: absolute;
    top: 50px;
    right: -22px;
  }

  @media screen and (min-width: $breakpoint-sm + 1) {
    width: 40%;
    svg {
      position: absolute;
      top: 200px;
      right: 50px;
    }
  }
}

.contact-address {
  padding-top: 10px;
  @media screen and (max-width: $breakpoint-sm + 1) {
    align-self: center;
    p {
      text-align: center
    }
  }

}

.form {
  flex-grow: 1;

  .form-field {
    flex-grow: 1;

    &.ui-error {
      input, textarea {
        color: darkred;
        border-bottom-color: darkred;
      }

      ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: darkred;
        opacity: 1; /* Firefox */
      }

      :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: darkred;
      }

      ::-ms-input-placeholder { /* Microsoft Edge */
        color: darkred;
      }

    }
  }

  input, textarea {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid rgba($white, 0.3);
    padding: 37px 25px 17px 25px;
    font-family: $font-family-base;
    font-weight: 300;
    font-size: $font-size-base;
    line-height: $line-height-base;
    color: $white;
    box-sizing: border-box;
    width: 100%;

    &::placeholder {
      color: rgba($white, 0.8)
    }

    &:focus {
      outline: none;
    }

    @media screen and (max-width: $breakpoint-sm) {
      font-size: $font-size-base-mobile;
      padding: 28px 16px 0px 16px;
    }

  }

  .fx-row {
    .form-field:first-of-type {
      margin-right: 30px
    }

    @media screen and (max-width: $breakpoint-sm) {
      flex-direction: column;
      align-items: stretch;
      .form-field:first-of-type {
        margin-right: unset
      }
    }
  }
}

.form-button {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  margin-top: 20px;
  width: 146px;
  height: 48px;
  margin-left: auto;
  background-image: url("/images/button-pattern.png");
  background-size: contain;
  cursor: pointer;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -ms-transition: .2s;
  -o-transition: .2s;
  transition: .2s;

  &:hover {
    background: rgba(#FFF, .1);
    -webkit-transition: .2s;
    -moz-transition: .2s;
    -ms-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
  }

  span {
    padding: 0 12px;
    align-self: center;
    font-weight: bold;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }

  @media screen and (max-width: $breakpoint-sm) {
    width: 100%;
    border: 1px solid rgba(255, 255, 255, 0.1);
  }
}

footer {
  margin-top: auto;
  padding-top: 45px;
  justify-content: space-between;

  .copyright {
    font-weight: 300;
    font-size: 14px;
    line-height: 14px;
    opacity: 0.7;
  }

  .social svg {
    opacity: 0.6;
    margin-left: 20px;
  }

  @media screen and (max-width: $breakpoint-sm) {
    flex-direction: column;
    .social {
      order: -1;
      margin-bottom: 26px;
    }
  }

}

</style>
